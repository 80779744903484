import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { PageLayout } from './blocks/PageLayout';
import { Content } from './blocks/Content';
import { PageFooter } from './PageFooter';
import { PageHeader } from './PageHeader';
import { Login } from './Login';

export const App = () => {
    return (
        <PageLayout>
            <PageHeader />
            <Content>
                <Switch>
                    <Route path="/" exact>
                        <HomePage />
                    </Route>
                    <Route path="/login" exact>
                        <Login/>
                    </Route>
                </Switch>
            </Content>
            <PageFooter />
        </PageLayout>
    );
};
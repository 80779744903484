import React from 'react';

import './PageFooter.css';

export const PageFooter = () => {

    return (
        <footer id="page-footer">
            <small>E-Mind.me, Inc.&nbsp;&middot;&nbsp;&nbsp;Copyright &copy;{new Date().getFullYear()}&nbsp;&middot;&nbsp;&nbsp;All Rights Reserved.</small>
        </footer>
    );
};

import React from 'react';
import { Link } from 'react-router-dom';
import './PageHeader.css';

export const PageHeader = () => {
    return (
        <header id="page-header">
            <div className="header">
            <Link to="/"><img id="logo" src="../images/EMindMe_index.gif" alt="E-Mind.me" /></Link>
            </div>
            <div className="login"><Link to="/login">Login</Link></div>
        </header>
        );
};
import React from 'react';

import './HomePage.css';

export const HomePage = () => {
    return (
        <>
        <section>
        <div class="content-paragraph"><p>For <span class="bolder">Service Providers</span>, such as dentists, doctors, accountants, hair stylists, and mechanics,
            E-Mind.Me helps you remind your customers about their appointments and meetings, using their preferred contact method, saving you time and money
            and missed schedules.</p><p>Send recurring electronic notifications for a fraction of the cost of postage and cards. Create custom notification templates
            using your own style and images to make your business look professional. View your schedule on your
            mobile device or computer.</p></div>
        <div class="content-paragraph"><p>For <span class="bolder">Individuals</span>, E-Mind.Me helps you remember your appointments without having
            to enter them into your calendar. Avoid the hassle of and cost of rescheduling missed meetings. Eliminate the need for saving appointment 
            reminder cards and post cards.</p><p>Create a free personal account and get notified how and when you like. Import your appointments to the private
            calendar software of your choice or view in a browser through your mobile device.</p></div>
        </section>
    </>
    );
}